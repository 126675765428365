import React, {useState, useContext} from "react";
import { Button } from '@common';
import MainHeading from '@typo/MainHeading'
import FlashMessage from '@notification/FlashMessage'
import {FirebaseContext} from '@components/Firebase';
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'



const PageWrapper = styled.div`
  padding:5%;
  background-color: ${props => props.theme.backgroundColor};
  min-height:100vh;
`

const Input =  styled.input`
  width:100%;
  label{
    font-size:12px;
    content: '${props => props.label}aaaaa';
    position:absolute;
    top:10px;
    opacity:0.8;
  }
  border:none;
  position:relative;
  background-color:white;
  height:65px;
  padding: 30px 15px 15px 15px;
  font-family:'poppins';
  border-radius:15px;
  margin-bottom: 15px;

`

const Register = (props) => {
  const {firebase } = useContext(FirebaseContext);
  const [flashMessage, setFlashMessage] = useState('');
  const { t } = useTranslation()

  const [formValues, setFormValues] = useState({
    password:'',
    confirmPassword: '',
  });

 
function handleInputChange(e){
  e.persist();
  setFlashMessage(''); // clean error message when inputs change
  setFormValues(currentValues => ( {
    ...currentValues,
    [e.target.name]: e.target.value
  }))
}
function handleSubmit(e){
  e.preventDefault();
  if(formValues.password === formValues.confirmPassword){
    firebase.changePassword(formValues.password).then(() => {
      setFlashMessage({"message": t('change-password.flashMessage-2'),"type":"success"});
    }).catch(error => {
      setFlashMessage({"message": error.message,"type":"error"});
    })
  }else{
    setFlashMessage({"message": t('change-password.flashMessage-1'),"type":"error"});
  }
}

  return (
    <PageWrapper around>
      <form onSubmit={handleSubmit}>
      <MainHeading center>{t('change-password.sectionTitle')}</MainHeading>
        <Input onChange={handleInputChange} value = {formValues.password} placeholder={t('change-password.inputPlaceholder-1')} type="password" required minLength={6} name="password"/>
        <Input onChange={handleInputChange} value = {formValues.confirmPassword} placeholder={t('change-password.inputPlaceholder-2')} type="password" required minLength={6} name="confirmPassword"/>
        {flashMessage && <FlashMessage message={flashMessage}/>}
        <Button type ="submit" block marginTop>{t('change-password.submitButton')}</Button>
      </form>
    </PageWrapper>
  )
}
export default Register